<template>
    <span :style="{'margin-left': (row.level??0) * levelIndent + 'px'}">
        <template v-if="row.isLoading">
            <span class="spinner-border spinner-border-sm text-primary me-2" role="status" aria-hidden="true"
                style="border-width: var(--bs-spinner-border-width); border-style: solid; border-color: currentColor; border-right-color: transparent;">
            </span>
            {{$t('Loading...')}}
        </template>
        <template v-else-if="row.hasNodes">
            <span class="o365-group-expand me-1 p-2" role="button" @click="row.expanded ? row.collapse() : row.expand()">
                <i :class="row.expanded ? 'bi bi-dash-square' : 'bi bi-plus-square'"></i>
            </span>
            <slot name="value">
                <span>
                    {{ displayValue }}
                </span>
            </slot>
        </template>
        <span v-else>
            <slot v-if="$slots.default"></slot>
            <slot v-else name="value"></slot>
        </span>
    </span>
</template>

<script setup lang="ts">
import type { NodeItem } from 'o365.modules.DataObject.extensions.NodeData.ts';
import { computed } from 'vue';
import $t from 'o365.modules.translate.ts';

const props = withDefaults(defineProps<{
    row: NodeItem,
    levelIndent?: number,
    hideCount?: boolean
}>(), {
    levelIndent: 24
});

const displayValue = computed(() => {
    const value = props.row.displayValue ?? $t('Blank');
    return (props.hideCount || !props.row.count) ? value : `${value} (${props.row.count})`;
});

</script>